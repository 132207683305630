interface Payload {
  website: string;
  hostname: string;
  screen: string;
  language: string;
  title: string;
  url: string;
  referrer: string;
}

interface EventData extends Payload {
  name: string;
  data?: Record<string, string>;
}

class Analytics {
  private readonly websiteId: string;
  private readonly hostUrl: string;
  private readonly autoTrack: boolean;
  private readonly domains: string[];
  private readonly endpoint: string;
  private readonly delayDuration: number;

  private currentUrl = '';
  private currentRef: string = document.referrer;
  private title: string = document.title;
  private cache?: string;
  private initialized = false;

  constructor({ id }: { id: string }) {
    this.websiteId = id;
    this.hostUrl = `${location.origin}/analytics`;
    this.autoTrack = false;
    this.domains = [];
    this.endpoint = `${this.hostUrl}/api/send`;
    this.delayDuration = 300;

    if (this.autoTrack && !this.trackingDisabled()) {
      this.handlePathChanges();
      this.handleTitleChanges();
      this.handleClicks();

      const init = () => {
        if (document.readyState === 'complete' && !this.initialized) {
          this.track();
          this.initialized = true;
        }
      };

      document.addEventListener('readystatechange', init, true);
      init();
    }
  }

  private getPath(url: string): string {
    try {
      return new URL(url).pathname;
    } catch (e) {
      return url;
    }
  }

  private getPayload(): Payload {
    const { width, height } = window.screen;
    return {
      website: this.websiteId,
      hostname: location.hostname,
      screen: `${width}x${height}`,
      language: navigator.language,
      title: encodeURIComponent(this.title),
      url: encodeURI(this.currentUrl),
      referrer: encodeURI(this.currentRef)
    };
  }

  private trackingDisabled(): boolean {
    return (
      (localStorage && localStorage.getItem('analytics.disabled') === 'true') ||
      (this.domains.length > 0 && !this.domains.includes(location.hostname))
    );
  }

  private async send(payload: EventData | Payload, type = 'event'): Promise<void> {
    if (this.trackingDisabled()) return;

    const headers = {
      'Content-Type': 'application/json'
    };
    if (typeof this.cache !== 'undefined') {
      headers['x-umami-cache'] = this.cache;
      headers['x-analytics-cache'] = this.cache;
    }

    try {
      const res = await fetch(this.endpoint, {
        method: 'POST',
        body: JSON.stringify({ type, payload }),
        headers
      });
      this.cache = await res.text();
    } catch (error) {
      console.error('Error sending analytics data:', error);
    }
  }

  private handlePush(_state: string, _title: string, url: string): void {
    if (!url) return;

    this.currentRef = this.currentUrl;
    this.currentUrl = this.getPath(url.toString());

    if (this.currentUrl !== this.currentRef) {
      setTimeout(() => this.track(), this.delayDuration);
    }
  }

  private handlePathChanges(): void {
    const hook = (method: string, callback: (state: string, title: string, url: string) => void) => {
      const orig = history[method];

      history[method] = (...args: [state: string, title: string, url: string]) => {
        callback(...args);
        return orig.apply(history, args);
      };
    };

    hook('pushState', this.handlePush.bind(this));
    hook('replaceState', this.handlePush.bind(this));
  }

  private handleTitleChanges(): void {
    const observer = new MutationObserver(([entry]) => {
      this.title = entry && entry.target ? entry.target.textContent || '' : '';
    });

    const node = document.querySelector('head > title');

    if (node) {
      observer.observe(node, {
        subtree: true,
        characterData: true,
        childList: true
      });
    }
  }

  private handleClicks(): void {
    document.addEventListener(
      'click',
      async (e) => {
        // TODO: add tracking code here
        console.log(`TODO: ${e}`);
      },
      true
    );
  }

  public track(obj?: Payload | string | ((payload: Payload) => Payload), data?: Record<string, string>): Promise<void> {
    if (typeof obj === 'string') {
      return this.send({
        ...this.getPayload(),
        name: obj,
        data: typeof data === 'object' ? data : undefined
      });
    } else if (typeof obj === 'object') {
      return this.send(obj);
    } else if (typeof obj === 'function') {
      return this.send(obj(this.getPayload()));
    }
    return this.send(this.getPayload());
  }
}

const analytics = new Analytics({ id: '3e9904d9-637c-418a-ae3d-cd5ce02950f7' });
export default analytics;
