import type { IActionType } from 'models/common';
import type { IComponentProps } from 'models/components';
import React, {useContext, createContext} from 'react';

import ArtifactMetaDataReducer, {
	IArtifactMetaDataState,
	initialArtifactMetaDataState
} from './ArtifactDetailReducer';

export interface IArtifactMetaDataContext {
  ArtifactMetaDataState: IArtifactMetaDataState;
  dispatch?: React.Dispatch<IActionType<IArtifactMetaDataState>>;
}

const initialArtifactMetaData: IArtifactMetaDataContext = {
	ArtifactMetaDataState: initialArtifactMetaDataState
};

export const ArtifactMetaDataContext = createContext<IArtifactMetaDataContext>(
	initialArtifactMetaData
);

export const useArtifactMetaDataContext = (): IArtifactMetaDataContext =>
	useContext(ArtifactMetaDataContext);

export const ArtifactMetaDataProvider: React.FC<IComponentProps> = (
	props: IComponentProps
) => {
	const [ArtifactMetaDataState, dispatch] = React.useReducer(
		ArtifactMetaDataReducer,
		initialArtifactMetaDataState
	);
	return (
		<ArtifactMetaDataContext.Provider value={{ ArtifactMetaDataState, dispatch }}>
			{props.children}
		</ArtifactMetaDataContext.Provider>
	);
};
