import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from 'views/ErrorPage/ErrorPage';

const DashboardLayout = React.lazy(() => import('layouts/Dashboard/Dashboard'));
const CertificationList = React.lazy(() => import('pages/CertificationList/CertificationList'));
const CertificationPage = React.lazy(() => import('pages/Certification/Certification'));
const CertificationResult = React.lazy(() => import('pages/CertificationResult/CertificationResult'));
const PartnerPage = React.lazy(() => import('pages/Partner/Partner'));
const PartnerList = React.lazy(() => import('pages/PartnerList/PartnerList'));
const ProductPage = React.lazy(() => import('pages/Product/Product'));
const ProductList = React.lazy(() => import('pages/ProductList/ProductList'));
const QuestionnaireForm = React.lazy(() => import('components/QuestionnaireForm/QuestionnaireForm'));
const KickstartFiles = React.lazy(() => import('components/KickstartFiles/KickstartFiles'));
const FvImages = React.lazy(() => import('components/FvImages/FvImages'));

export const AppRouter: React.FC = () => {
	// return useRoutes([
	//   {
	//     element: <DashboardLayout />,
	//     path: '/',
	//     children: [
	//       {
	//         index: true,
	//         element: <CertificationList />,
	//       },
	//       {
	//         path: '/home',
	//         children: [
	//           {
	//             index: true,
	//             element: <CertificationList />,
	//           }
	//         ]
	//       },
	//       {
	//         path: 'cases',
	//         children: [
	//           {
	//             index: true,
	//             element: <CertificationList />,
	//           },
	//           {
	//             element: <CertificationPage />,
	//             path: ':case_number'
	//           },
	//         ]
	//       },
	//       {
	//         path: 'certifications',
	//         children: [
	//           {
	//             index: true,
	//             element: <CertificationList />,
	//           },
	//           {
	//             element: <CertificationPage />,
	//             path: ':certificationId'
	//           },
	//         ]
	//       },
	//       {
	//         path: 'partners',
	//         children: [
	//           {
	//             index: true,
	//             element: <PartnerList />,
	//           },
	//           {
	//             path: ':partner_id',
	//             children: [
	//               {
	//                 index: true,
	//                 element: <PartnerPage />
	//               },
	//               {
	//                 path: ':tab',
	//                 element: <QuestionnaireForm />
	//               }
	//             ]
	//           }
	//           // {
	//           //   element: <PartnerPage />,
	//           //   path: ':partner_id',
	//           // },
	//         ]
	//       },
	//       {
	//         path: 'products',
	//         children: [
	//           {
	//             index: true,
	//             element: <ProductList />,
	//           },
	//           {
	//             element: <ProductPage />,
	//             path: ':product_id'
	//           },
	//         ]
	//       },
	//       {
	//         path: 'questionnaire',
	//         children: [
	//           {
	//             element: <QuestionnaireForm />,
	//             path: ':certificationId/:form_id'
	//           },
	//         ]
	//       },
	//       {
	//         element: <KickstartFiles />,
	//         path: 'kickstart'
	//       },
	//       {
	//         path: "*",
	//         element: <ErrorPage />,
	//       },
	//     ],
	//     errorElement: <ErrorPage />
	//   },
	//   {
	//     element: <ErrorPage />,
	//     path: '*'
	//   }
	// ]);


	return <Routes>
		<Route path='/' element={<DashboardLayout />}>
			{/* <Route path='new' element={<NewPost />} /> A nested route!*/}
			{/* <Route path=':postId' element={<Post />} /> A nested route! */}
			<Route path="/" element={<CertificationList />} />
			<Route path="/home" element={<CertificationList />} />
			<Route path="/certifications" element={<CertificationList />} />
			<Route path="/cases" element={<CertificationList />} />
			<Route path="/certifications/:certificationId/results/:uuid" element={<CertificationResult />} />
			<Route path="/cases/:caseNumber" element={<CertificationPage />} />
			<Route path="/cases/:caseNumber/discussion/:discussionId" element={<CertificationPage />} />
			<Route path="/certifications/:certificationId" element={<CertificationPage />} />
			<Route path="/partners/:partner_id" element={<PartnerPage />} />
			<Route path="/partners" element={<PartnerList />} />
			<Route path="/products/:product_id" element={<ProductPage />} />
			<Route path="/products/:product_id/:tab_id" element={<ProductPage />} />
			<Route path="/products/" element={<ProductList />} />
			<Route path="/questionnaire/:certificationId/:form_id" element={<QuestionnaireForm />} />
			<Route path="/kickstart" element={<KickstartFiles />} />
			<Route path="/fv-images" element={<FvImages />} />
			<Route path="*" element={<ErrorPage />} />
		</Route>
		<Route path="*" element={<ErrorPage />} />

	</Routes>;
};


