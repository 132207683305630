export function join(...arg) {
	return arg.join("/");
}

export function isObject(value) {
	return (
		value !== null &&
    typeof value === "object" &&
    value instanceof Array === false
	);
}

export function hasBody(method) {
	return "POST PUT PATCH".indexOf(method) > -1;
}

export function merge(a, b) {
	return {
		...(isObject(a) ? a : {}),
		...(isObject(b) ? b : {}),
	};
}