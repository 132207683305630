import type { ITestPlansData } from 'models/test-plans';
import type {
  ICertificationComment,
  ICertificationDetails,
  ICertificationFile,
  ICertificationState
} from 'models/certifications';

export enum CertificationDetailReducerConstants {
  getCertificationComments = 'getCertificationComments',
  getCertificationFiles = 'getCertificationFiles',
  getCertificationTestPlanSummary = 'getCertificationTestPlanSummary',
  updateCertification = 'updateCertification',
  reset = 'reset'
}

export const initialCertificationDetailState: ICertificationState = {
  certificationDetail: {} as ICertificationDetails,
  certificationComments: [],
  certificationFiles: [],
  certificationTestPlanSummary: {} as ITestPlansData
};

export type ICertificationActions =
  | {
      type: CertificationDetailReducerConstants.updateCertification;
      payload: { certificationDetail: ICertificationDetails };
    }
  | {
      type: CertificationDetailReducerConstants.getCertificationComments;
      payload: { certificationComments: ICertificationComment[] };
    }
  | {
      type: CertificationDetailReducerConstants.getCertificationFiles;
      payload: { certificationFiles: ICertificationFile[] };
    }
  | {
      type: CertificationDetailReducerConstants.getCertificationTestPlanSummary;
      payload: { certificationTestPlanSummary: ITestPlansData };
    }
  | {
      type: CertificationDetailReducerConstants.reset;
    };

export default (
  state: ICertificationState,
  action: ICertificationActions
): ICertificationState => {
  switch (action.type) {
    case CertificationDetailReducerConstants.updateCertification:
      return {
        ...state,
        certificationDetail: action.payload.certificationDetail
      };
    case CertificationDetailReducerConstants.getCertificationComments:
      return {
        ...state,
        certificationComments: action.payload.certificationComments
      };
    case CertificationDetailReducerConstants.getCertificationFiles:
      return {
        ...state,
        certificationFiles: action.payload.certificationFiles
      };
    case CertificationDetailReducerConstants.getCertificationTestPlanSummary:
      return {
        ...state,
        certificationTestPlanSummary:
          action.payload.certificationTestPlanSummary
      };
    case CertificationDetailReducerConstants.reset:
      return initialCertificationDetailState;
    default:
      return state;
  }
};
