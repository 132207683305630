import './App.scss';

import ArtifactsMetaData from 'context/artifacts/ArtifactsMetaData';
import React, { Suspense } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import LoadingPage from 'views/LoadingPage/LoadingPage';

import ToastAlertGroup from './components/General/Alert/ToastAlertGroup';
import { ErrorBoundary } from './components/General/Error/ErrorBoundary';
import AppProvider from './context/AppProvider';
import { AppRouter } from './routes/AppRouters';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AppProvider>
        <ArtifactsMetaData />
        <ToastAlertGroup />
        <Router>
          <Suspense fallback={<LoadingPage message='Authorizing ...' />}>
            <AppRouter />
          </Suspense>
        </Router>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
