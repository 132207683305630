/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component, ErrorInfo } from 'react';
import ErrorPage from 'views/ErrorPage/ErrorPage';

interface IProps {
  sentryAppName?: string;
  fallback?: React.FunctionComponent;
  errorMsgInfo?: string;
  isPageLevelError?: boolean;
  onError?: (error: Error, componentStack: string) => void;
  children?: React.ReactElement;
}

interface IState {
  hasError: boolean;
  error: Error;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps | Readonly<IProps>) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;
    this.setState({
      error,
      errorInfo
    });

    if (typeof onError === 'function') {
      try {
        onError.call(this, error, errorInfo?.componentStack);
      } catch (ignoredError) {}
    }
    // log error
  }
  readonly error = 'Unexpected Error Occurred, please try refreshing the page.';

  override render() {
    const { children, fallback: FallbackComponent } = this.props;
    if (this.state.hasError) {
      return this.props.fallback
? (
        <FallbackComponent />
      )
: (
        <ErrorPage error={this.state.error ? this.state.error : this.error} />
      );
    }
    return children || null;
  }
}
