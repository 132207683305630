import './ErrorPage.scss';

import React from 'react';

import {
  Bullseye,
  Text,
  TextContent,
  TextVariants
} from '@patternfly/react-core';
import { css } from '@patternfly/react-styles';

interface ErrorPageProps {
  isHeightLimited?: boolean;
  height?: 25 | 50 | 75 | 100;
  unit?: 'rem' | 'vh';
  error?: Error | string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error, height = 100, unit = 'vh' }) => (
  <Bullseye className={css(height && `pf-m-height-${height}-${unit}`, 'pf-m-text-align-center')}>
    <TextContent>
      {error ?
        <React.Fragment>
          <Text component={TextVariants.h2}>
            {typeof error === 'string' ? error : error.toString()}
          </Text>
          {(typeof error !== 'string' && error?.stack) && <Text component={TextVariants.p}>
            {error.stack}
          </Text>}
        </React.Fragment>
        : <React.Fragment>
          <Text component={TextVariants.h2}>
            Oops!
          </Text>
          <Text component={TextVariants.p}>
            Something went wrong
          </Text>
        </React.Fragment>
      }
    </TextContent>
  </Bullseye>
);

export default ErrorPage;
