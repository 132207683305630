import { getPartners } from 'api/partners';
import { getProductListings } from 'api/product';
import { useAlertContext } from 'context';
import { useFetch } from 'hooks';
import {
  ActionTypes,
  ProductContextType,
  ProductProviderProps,
  State
} from 'models/product-context.d';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  MenuToggle,
  MenuToggleElement,
  Modal,
  ModalVariant
} from '@patternfly/react-core';

import reducer from './ProductReducer';
import Env from 'utilities/env-utils';
import { ENV as SsoEnv } from '../../constants/env-constant';

const initialState: State = {
  isLoading: false,
  products: [],
  partners: []
};

const ProductContext = createContext<ProductContextType>({
  addProduct: () => null,
  updateProduct: () => null,
  getProductsByPartner: () => [],
  getProduct: () => null,
  ...initialState
});

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children
}) => {
  const [program, setProgram] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { request: fetchProducts } = useFetch(getProductListings, {
    propagateErrors: true
  });
  const { request: fetchPartners } = useFetch(getPartners, {
    propagateErrors: true
  });
  const { addFetchErrorMessage } = useAlertContext();

  let ignore = false;
  useEffect(() => {
    dispatch({ type: ActionTypes.START_LOADING });
    if (!ignore) {
      (async () => {
        try {
          await getProduct();
          await getPartner();
        } catch (e) {
          addFetchErrorMessage(e);
        } finally {
          dispatch({ type: ActionTypes.STOP_LOADING });
        }
      })();
    }
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (program) {
      if (['hardware', 'software'].includes(program.toLowerCase())) {
        window.open(
          getEnvUrl(`https://connect.redhat.com/en/programs`),
          '_blank'
        );
      } else {
        window.open(
          getEnvUrl(
            `https://connect.redhat.com/support/partner-acceleration-desk/#/case/new`
          ),
          '_blank'
        );
      }
    }
  }, [program]);

  const getProduct = async () => {
    try {
      const products = await fetchProducts();
      dispatch({ type: ActionTypes.GET_PRODUCTS, payload: products });
    } catch (e) {
      throw e;
    }
  };

  const getPartner = async () => {
    try {
      const partners = await fetchPartners();
      if (partners.length > 0) {
        dispatch({ type: ActionTypes.GET_PARTNERS, payload: partners });
      } else {
        setIsModalOpen(true);

        // window.open(`https://connect.redhat.com/en/programs`, '_blank');
      }
    } catch (e) {
      throw e;
    }
  };

  const contextValue: ProductContextType = {
    ...state,
    addProduct: (product) =>
      dispatch({ type: ActionTypes.ADD_PRODUCT, payload: product }),
    updateProduct: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_PRODUCT, payload: payload }),
    getProductsByPartner: (id) =>
      state.products.filter((product) => product.partner?.id === id),
    getProduct: (id) => {
      return state.products.find((product) => product?.id === id);
    }
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onSelect = (
    _event: React.MouseEvent<Element, MouseEvent> | undefined,
    value: string | number
  ) => {
    setProgram(value.toString());
    setIsDropdownOpen(!isDropdownOpen);
    onFocus();
  };

  const onFocus = () => {
    const element = document.getElementById('program-dropdown-toggle');
    (element as HTMLElement).focus();
  };

  const onEscapePress = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(!isDropdownOpen);
      onFocus();
    } else {
      handleModalToggle();
    }
  };

  function getEnvUrl(url) {
    const env = Env.getEnvName();
    if (env === SsoEnv.PROD) {
      return url;
    }
    return url.replace('.redhat.com', `.${env.toLowerCase()}.redhat.com`);
  }

  const dropdownItems = [
    <DropdownItem key='hardware' component='button'>
      Hardware
    </DropdownItem>,
    <DropdownItem key='software' component='button'>
      Software
    </DropdownItem>,
    <DropdownItem key='cloud' component='button'>
      Cloud
    </DropdownItem>
  ];

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
      <Modal
        title={`No Partner associated.`}
        titleIconVariant='danger'
        variant={ModalVariant.small}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key='confirm' variant='primary' onClick={handleModalToggle}>
            Confirm
          </Button>,
          <Button key='cancel' variant='link' onClick={handleModalToggle}>
            Cancel
          </Button>
        ]}
        onEscapePress={onEscapePress}
      >
        <div>
          <p>
            You do not have any Partner associated with this account. {program}
          </p>
          {!program
            ? (
            <span>Please select a program you wish to join.</span>
            )
            : ['hardware', 'software'].includes(program.toLowerCase())
              ? (
            <span>
              Please visit{' '}
              <a
                href={getEnvUrl('https://connect.redhat.com/en/programs')}
                target='_blank'
                rel='noopener noreferrer'
              >
                Red Hat Programs
              </a>
              .
            </span>
            )
              : (
            <span>
              Please raise a support request{' '}
              <a
                href={getEnvUrl(
                  'https://connect.redhat.com/support/partner-acceleration-desk/#/case/new'
                )}
                target='_blank'
                rel='noopener noreferrer'
              >
                {' '}
                here{' '}
              </a>
              .
            </span>
          )}
        </div>
        <br />
        <div>
          <Dropdown
            onSelect={onSelect}
            toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
              <MenuToggle
                ref={toggleRef}
                id='program-dropdown-toggle'
                variant='plain'
                isExpanded={isDropdownOpen}
                onClick={handleDropdownToggle}
              >
                {program ? program : 'Select Program'}
              </MenuToggle>
            )}
            isOpen={isDropdownOpen}
            // menuAppendTo='parent'
          >
            <DropdownList>{dropdownItems}</DropdownList>
          </Dropdown>
        </div>
      </Modal>
    </ProductContext.Provider>
  );
};

export function useProduct() {
  if (ProductContext === undefined) {
    throw new Error('useProduct must be used within an ProductProvider');
  }
  return useContext(ProductContext);
}
