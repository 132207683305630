import React from 'react';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import LoadingPage from 'views/LoadingPage/LoadingPage';

import { useAuth } from './AuthenticationContext';

export interface IProps extends React.PropsWithChildren { }

export default function AuthWrapper({ children }: IProps) {
  const { state } = useAuth();
  const { isRefreshing, isLoading, isAuthenticated, error } = state;
  // useEffect(() => {
  // if (!isLoading && !isRefreshing && !error) login();  
  // }, [])

  if (isLoading) return <LoadingPage message='Loading User Info ...' />;
  if (error) return <ErrorPage error={error} />;
  if (isRefreshing) return <LoadingPage message='Refreshing token ...' />;
  if (!isAuthenticated && !error && !isLoading) {
    // init state
    return <LoadingPage message='Authenticating ...' />;
  }

  return <>{children}</>;
}
