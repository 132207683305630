import { difference, forEach, isNil } from 'lodash';
import {
  IFormRequestInput,
  IFormRequestPatch,
  IOpUpdateRequestData
} from 'models/common';
import pako, { Data } from 'pako';
import React from 'react';

type ReplaceTemplateReturnType = (...values: number[]) => string;
type ReplaceTemplateType = (
  a: string | TemplateStringsArray,
  ...keys: number[]
) => ReplaceTemplateReturnType;
// parse a template literal
export const replaceTemplate: ReplaceTemplateType = (strings, ...keys) => {
  return function (...values) {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key: number, i): void => {
      const value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
};

type FormConsumableType<K> = (i: K, n: K) => K;
export const parseValuesToFormConsumable: FormConsumableType<{
  [index: string]: string;
}> = (initialValues, newValues) => {
  // to parse object values into formik digestible values
  const parsedValues = initialValues;
  forEach(Object.keys(initialValues), (key) => {
    parsedValues[key] = newValues[key] ?? initialValues[key];
  });
  return parsedValues;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const downloadFileBlob = (
  blob: Blob,
  x: { fileType: string; fileName: string }
): void => {
  const link = document.createElement('a');
  link.download = x.fileName;
  // const blob = b64toBlob(response.data, fileType);
  // const blob = new Blob([response.data]);
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
};

type b64toBlobType = (b64: string, f?: string, s?: number) => Blob;
export const b64toBlob: b64toBlobType = (
  b64Data,
  contentType = '',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const fileToBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(btoa(reader.result as string));
    reader.onerror = (error) => reject(error);
  });

export const zipToString = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () =>
      resolve(pako.inflate(reader.result as Data, { to: 'string' }));
    reader.onerror = (error) => reject(error);
  });

export const hasCertificationTestTag = async (file: File): Promise<boolean> => {
  try {
    if (file.type === 'text/xml') {
      // if (file.type === 'text/xml' || file.type === 'application/x-gzip') {
      // const xmlString = file.type === 'application/x-gzip' ? await zipToString(file) : await file.text();
      const xmlString = await file.text();
      if (window.DOMParser) {
        const source = new DOMParser().parseFromString(
          xmlString,
          'application/xml'
        );
        const certTest = source.getElementsByTagName('certification-test');
        return certTest.length > 0;
        // const cert = source.getElementsByTagName('certification');
        // if (cert) {
        //   certId = cert[0].id;
        // }
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getArrayDifference = (
  key: string,
  original = [],
  updated = []
): IOpUpdateRequestData[] => {
  const result = [];
  const removed = difference(original, updated);
  const added = difference(updated, original);
  if (removed.length > 0) {
    result.push({ path: `/${key}`, op: 'remove', value: removed });
  }
  if (added.length > 0) {
    result.push({ path: `/${key}`, op: 'update', value: added });
  }
  return result;
};

const ALLOWED_ELEMENTS = [
  'ARTICLE',
  'A',
  'CONTAINER',
  'H1',
  'H2',
  'H3',
  'H4',
  'P',
  'SECTION',
  'SPAN',
  'STRONG'
];

export const parseHtml = (html: string): React.ReactElement[] => {
  const result = [];
  const nodeList: NodeListOf<ChildNode> = new DOMParser().parseFromString(
    html,
    'text/html'
  ).body.childNodes;
  Array.from(nodeList).forEach((node: ChildNode, index) => {
    const elem = node as HTMLElement;
    if (
      (!elem.tagName && elem.textContent) ||
      (elem.tagName && ALLOWED_ELEMENTS.indexOf(elem.tagName) > -1)
    ) {
      if (elem.childNodes.length > 0) {
        const nodes = Array.from(elem.childNodes).map((childNode, idx) => {
          return createElement(childNode as HTMLElement, `${index}-${idx}`);
        });
        result.push(createElement(elem, index, nodes));
      } else {
        result.push(createElement(elem, index));
      }
    }
  });
  return result;
};

const createElement = (
  elem: HTMLElement,
  key: string | number,
  childNodes?: React.ReactNode
) => {
  const attr = {};
  if (elem.attributes && elem.attributes.length > 0) {
    for (let i = 0; i < elem.attributes.length; i++) {
      const name = elem.attributes[i].name;
      attr[name] = elem.getAttribute(name);
    }
  }
  return React.createElement(
    (elem.tagName || 'SPAN').toLowerCase(),
    { key, ...attr },
    childNodes ? childNodes : elem.innerText || elem.textContent
  );
};

export const formatURL = (url: string): string => {
  const hasProto = new RegExp(/^(https?:\/\/)/gi).test(url);
  return `${hasProto ? '' : 'https://'}${url}`;
};

export const getDateISO = (prior: number): string => {
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - prior));
  return priorDate.toISOString().split('T')[0];
};

export const getFormData = <T>(object: T): FormData => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

/**
 * Compares two objects and returns an array of patches representing the differences between the original and modified objects.
 *
 * @param {Record<string, number | number[] | string | boolean>} original - The original object.
 * @param {Record<string, number | number[] | string | boolean>} modified - The modified object.
 * @returns {(IFormRequestPatch[] | [])} - An array of patches representing the differences between the original and modified objects.
 *                                         An empty array is returned if there are no differences.
 * @typedef {Object} IFormRequestPatch
 * @property {string} path - The property path of the patch.
 * @property {string} op - The type of patch operation, either 'add', 'update', or 'remove'.
 * @property {(number | number[] | string | boolean)} value - The value of the patch.
 */
export const createPatch = <K = IFormRequestInput>(
  original: K,
  modified: K,
  op?: 'add' | 'update' | 'remove'
): IFormRequestPatch[] | [] => {
  const result: IFormRequestPatch[] = [];
  for (const a in modified) {
    const key: string = a;
    if (!original.hasOwnProperty(key)) {
      result.push({
        path: '/' + key,
        op: op ? op : 'add',
        value: modified[key]
      });
    } else if (Array.isArray(original[key]) && Array.isArray(modified[key])) {
      if (original[key].toString() !== modified[key].toString()) {
        const arr: Array<string | number> = original[key] as Array<
          string | number
        >;
        result.push({
          path: '/' + key,
          op: arr.length > 0 ? 'update' : 'add',
          value: modified[key]
        });
      }
    } else if (original[key] !== modified[key]) {
      result.push({
        path: '/' + key,
        op: 'update',
        value: modified[key]
      });
    }
  }
  for (const b in original) {
    const key: string = b;

    if (!modified.hasOwnProperty(key)) {
      result.push({
        path: '/' + key,
        op: 'remove',
        value: original[key]
      });
    }
  }
  return result;
};

export const isNotEmpty = (value) => !isNil(value) && value !== '';

interface UpdateOperation {
  op: 'update';
  path: string;
  value: string;
}

export function updateRequest<T extends Record<string, string>>(
  input: T
): UpdateOperation[] {
  const operations: UpdateOperation[] = [];
  for (const [key, value] of Object.entries(input)) {
    const path = `/${key}`;
    operations.push({
      op: 'update',
      path,
      value
    });
  }
  return operations;
}
