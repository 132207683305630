import type { ICertificationState } from 'models/certifications';
import type { IComponentProps } from 'models/components';
import React, { createContext, useContext } from 'react';

import CertificationDetailReducer, {
  ICertificationActions,
  initialCertificationDetailState
} from './CertificationDetailReducer';

export interface ICertificationContext {
  certificationDetailState: ICertificationState;
  dispatch: React.Dispatch<ICertificationActions>;
}

const initialCertificationDetail: ICertificationContext = {
  certificationDetailState: initialCertificationDetailState,
  dispatch: () => { /* empty */ }
};

export const CertificationDetailContext = createContext<ICertificationContext>(
  initialCertificationDetail
);

export const useCertificationDetailContext = (): ICertificationContext =>
  useContext(CertificationDetailContext);

export const CertificationDetailsProvider: React.FC<IComponentProps> = (
  props
) => {
  const [certificationDetailState, dispatch] = React.useReducer(
    CertificationDetailReducer,
    initialCertificationDetailState
  );
  return (
    <CertificationDetailContext.Provider
      value={{ certificationDetailState, dispatch }}
    >
      {props.children}
    </CertificationDetailContext.Provider>
  );
};
