import { IProductVersion } from 'models/products';
import ENV from '../../utilities/env';
import { getUri } from '../../utilities/fetch';

// GETs all artifacts
export function getRedHatProductVersions(): Promise<IProductVersion[]> {
  const uri = ENV.apiHostName
    .clone()
    .setPath(`${ENV.pathPrefix}${ENV.apiVersion}/artifacts/redhat/versions`);
  return getUri(uri);
}
