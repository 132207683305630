import { useAlertContext } from 'context/alert/AlertContext';
import React from 'react';

import {
  Alert,
  AlertActionCloseButton,
  AlertGroup,
  AlertVariant
} from '@patternfly/react-core';

const defaultTimeout = 15000;
const ToastAlertGroup: React.FC = () => {
  const { alerts, removeAlert } = useAlertContext();
  return (
    <>
      <AlertGroup isToast>
        {alerts.map(({ key, variant, title, message = '', timeout = defaultTimeout }) => (
          <Alert
            timeout={timeout}
            isLiveRegion
            variant={AlertVariant[variant]}
            title={title}
            actionClose={
              <AlertActionCloseButton
                title={title}
                variantLabel={`${variant} alert`}
                onClose={() => removeAlert(key)}
              />
            }
            key={key}>
            {message}
          </Alert>
        ))}
      </AlertGroup>
    </>
  );
};

export default ToastAlertGroup;
