import includes from 'lodash/includes';

// interfaces definitions
interface IError {
  message: string;
  stack?: string;
  extra?: {
    url: string;
    params: RequestInit;
  };
}

export interface IAdditionalOptions {
  dataType?: string;
  externalUrl?: boolean;
  useCookieOnly?: boolean;
}

export const defaultAdditionalOptions: IAdditionalOptions = {
	dataType: '',
	externalUrl: false,
	useCookieOnly: false
};

const baseParams: RequestInit = {
	// adding this because according to MDN, fetch will set credentials to same-origin by default,
	// which will let to inclusion of cookies into the request
	// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
	credentials: 'omit',
	headers: {
		Accept: 'application/json'
	},
	signal: null
};

function getIsTokenExpired() {
	return window.keycloak?.isTokenExpired || (() => false);
}

// If the token is expiring within 30 seconds, go ahead and refresh it.  Using 30 seconds considering jwt.js checks if
// the token needs to be refreshed every 58 seconds with a TTE of 90 seconds.  So 30 seconds guarantees that
// we are at the boundary of what jwt.js does without overlapping a great deal
function isTokenAboutToExpire(delay = 30): boolean {
	const _isTokenExpired = getIsTokenExpired();

	return _isTokenExpired(delay);
}

function forceTokenRefresh(): Promise<boolean> {
	const _isTokenExpired = getIsTokenExpired();
	console.warn('Hydrajs detected the JWT token has expired, forcing an update');
	return new Promise((resolve, reject) => {
		// updateToken(true) forces the token to update by passing -1 to keycloak.updateToken
		const updateTokenCall = window.keycloak.updateToken(30);
		const callbackFn = (refreshed: boolean) => {
			if (refreshed) {
				resolve(true);
			}
			// If the token hasn't been refreshed but the token isn't expired yet resolve true
			else if (!refreshed && !_isTokenExpired(0)) {
				resolve(true);
			}
			// If the token hasn't been refreshed and the token is expired reject
			else if (!refreshed && _isTokenExpired(0)) {
				reject(new Error('Token not refreshed and is expired.'));
			}
		};

		if (updateTokenCall.success) {
			updateTokenCall.success(callbackFn).error((e: boolean) => {
				reject(e);
			});
		} else {
			updateTokenCall.then(callbackFn).catch((e: Error) => {
				reject(e);
			});
		}
	});
}

function getAuthToken() {
	if (window.keycloak && window.keycloak.token) {
		if (window.keycloak.authenticated) {
			return `Bearer ${window.keycloak.token}`;
		}
	}
	return null;
}

// helper functions
const errorHandler = (response: Response) => {
	return response.text().then((body) => {
		if (body == null || body === '') {
			const error = new Error(body);
			Object.assign(
				error,
				{ status: response.status },
				{ statusText: response.statusText }
			);
			throw error;
		}
		let parsedError;
		try {
			parsedError = JSON.parse(body);
		} catch (e) {
			// tslint:disable-next-line:no-console
			console.error(e);
		}
		if (parsedError) {
			const error = new Error((parsedError && parsedError.message) || body);
			Object.assign(
				error,
				parsedError,
				{ isApiError: true },
				{ status: response.status },
				{ statusText: response.statusText }
			);
			throw error;
		} else {
			const error = new Error(body);
			Object.assign(
				error,
				{ status: response.status },
				{ statusText: response.statusText }
			);
			throw error;
		}
	});
};

const responseHandler = <T>(
	response: Response,
	dataType?: string
): Promise<T | string | Blob> | Response => {
	try {
		if (response.ok) {
			const contentType = response.headers.get('content-type');
			if (dataType !== 'text' && contentType && includes(contentType, 'json')) {
				return response
					.json()
					.then((j) => Promise.resolve(j))
					.catch(() => Promise.resolve({}));
			} else if (
				dataType === 'text' ||
        (contentType && includes(contentType, 'text') && dataType !== 'raw')
			) {
				return response.text();
			} else if (dataType === 'raw') {
				return response;
			} else if (includes(contentType, 'image')) {
				return response.blob();
			} else {
				// Defaulting to text if content type cannot be determined
				// https://github.com/github/fetch/issues/268#issuecomment-176544728
				return response
					.text()
					.then((j) => Promise.resolve(j ? JSON.parse(j) : {}))
					.catch(() => Promise.resolve({}));
			}
		} else {
			return errorHandler(response);
		}
	} catch {
		return errorHandler(response);
	}
};

const isError = (error: IError): boolean => {
	return error && error.message != null;
};

const processCaughtError = (uri: Uri, params: RequestInit, error: IError) => {
	try {
		if (isError(error)) {
			error.extra = {
				url: uri.toString(),
				params
			};
		}
	} catch (e) {
		throw e;
	}
};

const fetchURIWithParams = async <T>(
	uri: Uri,
	params: RequestInit,
	dataType?: string
): Promise<T> => {
	try {
		const response = await fetch(uri.toString(), params);
		return responseHandler<T>(response, dataType) as Promise<T>;
	} catch (error: unknown) {
		processCaughtError(uri, params, error as IError);
		return Promise.reject(error);
	}
};

const callFetchAndHandleJwt = async <T>(
	uri: Uri,
	params: RequestInit,
	additionalOptions?: IAdditionalOptions
): Promise<T> => {
	const isIE11 = !window.ActiveXObject && 'ActiveXObject' in window;
	if (isTokenAboutToExpire() && !additionalOptions.externalUrl) {
		await forceTokenRefresh();
	}
	if (!additionalOptions.externalUrl) {
		if (!isIE11 && getAuthToken() && !additionalOptions.useCookieOnly) {
			params.headers['Authorization'] = getAuthToken();
		} else {
			// In IE servers give cached responses thus adding cache burst param
			isIE11 && uri.addQueryParam('cache-burst-param', new Date().getTime());
			// tslint:disable-next-line:no-console
			console.info(
				`Didn't add Auth headers ${
					isIE11
						? 'in IE11 browser'
						: additionalOptions.useCookieOnly
							? 'explicitly mentioned to use cookie'
							: 'as token was missing'
				}, relying on auth cookie being passed`
			);
		}
	}
	// params.headers['Host'] = 'https://' + uri.host();
	return fetchURIWithParams<T>(uri, params, additionalOptions.dataType);
};

const mergeRequestOptions = (params: RequestInit, extraParams: RequestInit) => {
	const { headers, ...nonHeaderParams } = extraParams;
	params.headers = params.headers ? params.headers : {};
	Object.assign(params.headers, headers);
	Object.assign(params, { ...nonHeaderParams });
};

export const addQueryParamsToUri = (
	uri: Uri,
	params: RequestInit,
	includeEmptyString = false
): void => {
	params &&
    Object.keys(params).forEach((k) => {
    	// add false and 0 to query params
    	if (
    		params[k] ||
        params[k] === false ||
        params[k] === 0 ||
        (includeEmptyString && params[k] === '')
    	) {
    		uri.addQueryParam(k, params[k]);
    	}
    });
};

export const getUri = <T = string | Blob>(
	uri: Uri,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'GET'
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt<T>(uri, params, additionalOptions);
};

export const getUriWithBody = <T>(
	uri: Uri,
	body?: BodyInit,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const postUri = <T>(
	uri: Uri,
	body?: BodyInit,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: JSON.stringify(body)
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const postFormUri = <T>(
	uri: Uri,
	formData: FormData,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'POST',
		headers: {},
		body: formData
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const putUri = <T>(
	uri: Uri,
	body: BodyInit,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const patchUri = <T>(
	uri: Uri,
	body: BodyInit,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const deleteUri = <T>(
	uri: Uri,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'DELETE'
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const deleteUriWithBody = <T>(
	uri: Uri,
	body: BodyInit,
	extraParams?: RequestInit,
	additionalOptions = defaultAdditionalOptions
): Promise<T> => {
	const params: RequestInit = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	};
	mergeRequestOptions(params, baseParams);
	extraParams && mergeRequestOptions(params, extraParams);
	return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const isAuthenticated = (): boolean => {
	if (getAuthToken() && !isTokenAboutToExpire(0)) {
		return true;
	}
	return false;
};
