import { IFvImages, ISoftwareSubCategoryResponse } from 'models/artifacts';
import { IArtifactAll } from 'models/certifications';

import ENV from '../../utilities/env';
import { getUri } from '../../utilities/fetch';

export function getAllArtifacts(): Promise<IArtifactAll> {
  const uri = ENV.apiHostName
    .clone()
    .setPath(`${ENV.pathPrefix}${ENV.apiVersion}/artifacts/all`);
  return getUri(uri);
}

export function getSubCategoryArtifacts(): Promise<ISoftwareSubCategoryResponse> {
  const uri = ENV.apiHostName
    .clone()
    .setPath(
      `${ENV.pathPrefix}${ENV.apiVersion}/artifacts/vocabularies?category=software-sub-category`
    );
  return getUri(uri);
}

export function getFvImages(): Promise<IFvImages[]> {
  const uri = ENV.apiHostName
    .clone()
    .setPath(`${ENV.pathPrefix}${ENV.apiVersion}/fv-images`);
  return getUri(uri);
}

export function getFvImagePreSignUrl({
  majorVersion,
  arch,
  name
}: IFvImages): Promise<{ url: string; expiration: EpochTimeStamp }> {
  const uri = ENV.apiHostName
    .clone()
    .setPath(
      `${ENV.pathPrefix}${ENV.apiVersion}/fv-images/${majorVersion}/${arch}/${name}/presigned-url`
    );
  return getUri(uri);
}

export function getSubscriptionModel(
  type: string
): Promise<{ name: string }[]> {
  const uri = ENV.apiHostName
    .clone()
    .setPath(
      `${ENV.pathPrefix}${ENV.apiVersion}/artifacts/${type}-subscription-models`
    );
  return getUri(uri);
}
