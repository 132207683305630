import { Action, State } from '../../models/product-context';

export default (state: State, action: Action): State => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'STOP_LOADING':
      return { ...state, isLoading: false };
    case 'GET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'GET_PARTNERS':
      return { ...state, partners: action.payload };
    case 'ADD_PRODUCT':
      return { ...state, products: [...state.products, action.payload] };
    case 'UPDATE_PRODUCT':
      const index =
        state.products && Array.isArray(state.products)
          ? state.products.findIndex(
              (product) => product.id === action.payload.id
            )
          : 0;

      if (index > 0) {
        state.products[index] = { ...state.products[index], ...action.payload };
        return {
          ...state,
          products: [...state.products]
        };
      } else {
        return {
          ...state,
          products: [...state.products, action.payload]
        };
      }

    default:
      return state;
  }
};
