import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getApiUrl } from 'utilities/env-utils';
import { join } from 'utilities/network-utils';

export class Client {
  instances = {
    hive: "hydra/rest/cwe",
    hydra: "hydra/cwe/rest/v1.0",
  };
  path = {
    endpoint: getApiUrl(),
    namespace: "",
    resource: "",
  };
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  transport: AxiosInstance;

  constructor() {
    this.path.namespace = this.instances["hive"];
    this.transport = axios.create({
      baseURL: this.path.endpoint,
    });
  }

  namespace(namespace: string) {
    this.path.namespace = namespace;
    return this;
  }

  interceptors() {
    this.transport.interceptors.request.use((config) => {
      for (const [key, value] of Object.entries(this.headers)) {
        config.headers[key] = value;
      }
      return config;
    });

    this.transport.interceptors.response.use(
      (response) => response && response.data,
      (error) => Promise.reject(error)
    );
    return this;
  }

  resource(resource: string) {
    this.path.resource = resource;
    return this;
  }

  authorization(token: string) {
    this.transport.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    return this;
  }

  request(config?: AxiosRequestConfig) {
    return this.instance().request(config);
  }

  endpoint() {
    const url = join(
      this.path.endpoint,
      this.path.namespace,
      this.path.resource
    );
    this.transport.defaults.baseURL = url;
    return this;
  }

  instance() {
    return this.interceptors().endpoint().transport;
  }
}

const client = new Client();
export { client };
