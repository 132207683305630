export enum ENV {
  PROD = 'PROD',
  STAGE = 'STAGE',
  QA = 'QA',
  DEV = 'DEV',
  DEV1 = 'DEV1',
  DEV2 = 'DEV2'
}

export enum NODE_ENV {
  PRODUCTION = 'PRODUCTION',
  DEVELOPMENT = 'DEVELOPMENT'
}

export const NODE_VARIABLES = {
  NODE_ENV: 'NODE_ENV',
  REACT_APP_API_URL: 'REACT_APP_API_URL',
  REACT_APP_API_KEY: 'REACT_APP_API_KEY',
  REACT_APP_ANOTHER_VAR: 'REACT_APP_ANOTHER_VAR'
};

export type I_HOSTNAME = {
  [key in ENV]: string[];
};
export const HOSTNAMES: I_HOSTNAME = {
  [ENV.PROD]: [
    'connect.redhat.com',
    'prod.foo.redhat.com',
    'access.redhat.com',
    'rhcert.connect.redhat.com',
    'rhcert-spa-certpx-prod.ext.us-west.aws.prod.paas.redhat.com'
  ],
  [ENV.STAGE]: [
    'stage-connect.redhat.com',
    'access.stage.redhat.com',
    'stage.foo.redhat.com',
    'rhcert.connect.preprod.redhat.com',
    'rhcert-spa-certpx-stage.ext.us-east.aws.preprod.paas.redhat.com',
    'certops-admin-ui-v2-certpx-stage.apps.ext.spoke.preprod.us-east-1.aws.paas.redhat.com'
  ],
  [ENV.QA]: [
    'connect.qa.redhat.com',
    'qa.foo.redhat.com',
    'access.qa.redhat.com',
    'rhcert.connect.dev.redhat.com',
    'rhcert-spa-certpx-qa.ext.us-east.aws.preprod.paas.redhat.com',
    'certops-admin-ui-certpx-dev.apps.ext.spoke.preprod.us-east-1.aws.paas.redhat.com',
    'certops-admin-ui-v2-certpx-qa.apps.ext.spoke.preprod.us-east-1.aws.paas.redhat.com'
  ],
  [ENV.DEV]: ['fte.foo.redhat.com'],
  [ENV.DEV1]: [],
  [ENV.DEV2]: []
};

export const HYDRA_HOSTNAMES = {
  [ENV.QA]: 'https://access.qa.redhat.com',
  [ENV.STAGE]: 'https://access.stage.redhat.com',
  [ENV.PROD]: 'https://access.redhat.com'
};

export const SSO_HOSTNAMES = {
  [ENV.QA]: 'https://sso.qa.redhat.com/auth/',
  [ENV.STAGE]: 'https://sso.stage.redhat.com/auth/',
  [ENV.PROD]: 'https://sso.redhat.com/auth/'
};

export const HYDRA_CONFIG = {
  version: 'v1.0',
  prefix: 'hydra/cwe/rest'
};

export const HIVE_CONFIG = {
  version: null,
  prefix: 'hydra/rest/cwe'
};
