import type { IFormRequestPatch, ILogoUpload } from 'models/common';
import type { IPartner, PublicPartner } from 'models/partner';
import type { IProduct, Make } from 'models/products';
import { getFormData } from 'utilities/parser-utils';

import ENV from '../../utilities/env';
import {
  deleteUri,
  getUri,
  patchUri,
  postFormUri
} from '../../utilities/fetch';

// GETs all user's partners
export function getPartners(): Promise<IPartner[]> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/partners`);

	return getUri(uri);
}

export function getPartner(id: string): Promise<IPartner> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/partners/${id}`);

	return getUri(uri);
}

export function getPartnerProducts(id: number): Promise<IProduct[]> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/partners/${id}/products`);

	return getUri(uri);
}

export function updatePartner(
	id: number,
	payload: [] | IFormRequestPatch[]
): Promise<IPartner> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/partners/${id}`);

	return patchUri(uri, payload as unknown as BodyInit);
}

export function getPublicPartner(): Promise<PublicPartner[]> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/partners/public`);
	return getUri(uri);
}

export function getPartnerMakes(id: number): Promise<Make[]> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/makes?partnerId=${id}`);
	return getUri(uri);
}

export function getPartnerLogo(id: string): Promise<Blob> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/public/partners/${id}/logo`);
	return getUri<Blob>(uri);
}

export function updatePartnerLogo(
	partnerId: string,
	data: ILogoUpload
): Promise<Response> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(
			`${ENV.pathPrefix}${ENV.apiVersion}/partners/${partnerId}/attachments/upload`
		);
	return postFormUri(uri, getFormData(data));
}

export function deletePartnerLogo(id: string): Promise<Response> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/partners/${id}/logo`);
	return deleteUri(uri);
}
