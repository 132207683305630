import { IRelationshipResponse } from 'models/certifications';
import { ILogoUpload } from 'models/common';
import { CertificationsSPOG, IProduct } from 'models/products';
import { getFormData } from 'utilities/parser-utils';

import ENV from '../../utilities/env';
import {
	deleteUri,
	getUri,
	patchUri,
	postFormUri,
	postUri
} from '../../utilities/fetch';

// GETs all the Products Listings
export function getProductListings(): Promise<IProduct[]> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/products`);
	return getUri(uri);
}

export function getProductDetail(productId: number): Promise<IProduct> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/products/${productId}`);
	return getUri(uri);
}

export function getProductCertificationSPOG(
	productId: number
): Promise<CertificationsSPOG> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(
			`${ENV.pathPrefix}${ENV.apiVersion}/products/${productId}/certificationsSPOG`
		);
	return getUri(uri);
}

export function createProduct(reqData: BodyInit): Promise<IProduct> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/products`);
	return postUri(uri, reqData);
}

export function updateProduct(
	productId: number,
	reqData: BodyInit
): Promise<Record<string, unknown>> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/products/${productId}`);
	return patchUri(uri, reqData);
}

export function getProductRelationship(
	productId: number
): Promise<IRelationshipResponse> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(
			`${ENV.pathPrefix}${ENV.apiVersion}/products/relationship?partnerProductId=${productId}`
		);
	return getUri(uri);
}

export function getProductLogo(id: string): Promise<Blob> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/public/products/${id}/logo`);
	return getUri<Blob>(uri);
}

export function updateProductLogo(
	id: string,
	data: ILogoUpload
): Promise<Response> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(
			`${ENV.pathPrefix}${ENV.apiVersion}/products/${id}/attachments/upload`
		);
	return postFormUri(uri, getFormData(data));
}

export function deleteProductLogo(id: string): Promise<Response> {
	const uri = ENV.apiHostName
		.clone()
		.setPath(`${ENV.pathPrefix}${ENV.apiVersion}/products/${id}/logo`);
	return deleteUri(uri);
}
