
import {
  AlertContextProvider,
  ArtifactMetaDataProvider,
  AuthProvider,
  CertificationDetailsProvider,
  FeatureProvider,
  ProductProvider
} from 'context';
import React from 'react';

import type { IComponentProps } from 'models/components';
const AppProvider: React.FC<IComponentProps> = (props) => {
  return (
    <AuthProvider>
      <FeatureProvider>
        <ArtifactMetaDataProvider>
          <AlertContextProvider>
            <ProductProvider>
              <CertificationDetailsProvider>
                <React.StrictMode>{props.children}</React.StrictMode>
              </CertificationDetailsProvider>
            </ProductProvider>
          </AlertContextProvider>
        </ArtifactMetaDataProvider>
      </FeatureProvider>
    </AuthProvider>
  );
};

export default AppProvider;
