import * as React from 'react';

export function useIsMounted () {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    // return () => (mounted.current = false)  // this was causing breaking crashes need to revist.
  }, []);
  return mounted;
}
