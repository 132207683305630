import { getAllArtifacts } from 'api/artifacts/artifact';
import { getRedHatProductVersions } from 'api/artifacts/redHatProducts';
import { useFetch } from 'hooks';
import React, { useEffect } from 'react';

import { ArtifactMetaDataReducerConstants } from './ArtifactDetailReducer';
import { useArtifactMetaDataContext } from './ArtifactsDetailContext';

import type { IArtifactAll } from 'models/certifications';

const ArtifactsMetaData: React.FC = () => {
	const { request } = useFetch<IArtifactAll, []>(getAllArtifacts, {
		propagateErrors: true
	});
	const { request: productsRequest } = useFetch(getRedHatProductVersions, {
		propagateErrors: true
	});
	const { dispatch } = useArtifactMetaDataContext();

	useEffect(() => {
		(async () => {
			try {
				const response = await request();
				dispatch({
					type: ArtifactMetaDataReducerConstants.getArtifactMetaData,
					payload: {
						ecosystems: response.ecosystems,
						productCategories: response.categories,
						certificationTypes: response.certificationTypes,
						platforms: response.platforms,
						taxonomyVocabularies: response.taxonomyVocabularies
					}
				});
			} catch (e) {}
		})();
	}, []);
	useEffect(() => {
		(async () => {
			try {
				const response = await productsRequest();
				dispatch({
					type: ArtifactMetaDataReducerConstants.getRedHatProductVersions,
					payload: {
						redHatProductVersions: response
					}
				});
			} catch (e) {}
		})();
	}, []);

	return null;
};

export default ArtifactsMetaData;
