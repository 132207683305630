import { IAlert } from "./AlertContext";

type IAlertState = IAlert[];

export function AlertReducer (state: IAlertState, action: { type: string; alert?: IAlert; key?: number; }): IAlertState {
  const key = state.length > 0? state[state.length - 1].key + 1: 1;
  switch (action.type) {
  case 'ADD':
    return [...state, { ...action.alert, key }];
  case 'REMOVE':
    return [...state.filter((el) => el.key !== action.key)];
  default:
    return state;
  }
}
