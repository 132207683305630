import React, { createContext, useContext, useState } from 'react';

type Feature = {
	id: string;
	name: string;
	enabled: boolean;
};

type FeatureContextType = {
	features: Feature[];
	setFeatureEnabled: (id: string, enabled: boolean) => void;
	isFeatureEnabled: (id: string) => boolean;
};

const initialState = [{ id: 'CERTPX-10960', name: 'Marketing Data', enabled: true }];

export const FeatureContext = createContext<FeatureContextType>({
	features: [...initialState],
	setFeatureEnabled: () => ({}),
	isFeatureEnabled: () => false,
});

interface FeatureProviderProps extends React.PropsWithChildren { }
export const FeatureProvider: React.FC<FeatureProviderProps> = ({ children }) => {
	const [features, setFeatures] = useState<Feature[]>(() => {
		const storedValue = localStorage.getItem("features");
		return storedValue ? [...JSON.parse(storedValue), ...initialState] : [...initialState];
	});

	const setFeatureEnabled = (id: string, enabled: boolean) => {
		setFeatures((prevFeatures) =>
			prevFeatures.map((feature) =>
				feature.id === id ? { ...feature, enabled } : feature
			)
		);
	};

	const isFeatureEnabled = (id: string) => {
		const feature = features.find((f) => f.id === id);
		return feature ? feature.enabled : false;
	};

	return (
		<FeatureContext.Provider value={{ features, setFeatureEnabled, isFeatureEnabled }}>
			{children}
		</FeatureContext.Provider>
	);
};

export function useFeature() {
	if (FeatureContext === undefined) {
		throw new Error('useFeature must be used within an FeatureProvider');
	}
	return useContext(FeatureContext);
}
