import './LoadingPage.scss';

import React from 'react';

import {
  Bullseye,
  Flex,
  Spinner,
  Text,
  TextContent,
  TextVariants
} from '@patternfly/react-core';
import { css } from '@patternfly/react-styles';

interface LoadingPageProps {
  isHeightLimited?: boolean;
  message?: string;
  height?: 25 | 50 | 75 | 100;
  unit?: 'rem' | 'vh';
}

const LoadingPage: React.FC<LoadingPageProps> = ({
  message = 'Loading',
  height = 100,
  unit = 'vh'
}) => (
  <Bullseye className={css(height && `pf-m-height-${height}-${unit}`)}>
    <Flex
      className={css('pf-m-gap-sm')}
      alignItems={{ default: 'alignItemsCenter' }}
      justifyContent={{ default: 'justifyContentCenter' }}
    >
      <Spinner diameter='1.5rem' isInline aria-label='Loading' />
      <TextContent>
        <Text className='pf-v5-u-font-weight-light' component={TextVariants.h2}>
          {message}
        </Text>
      </TextContent>
    </Flex>
  </Bullseye>
);

export default LoadingPage;
