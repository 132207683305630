// Since we aren't transpiling to babel can't use ES6 imports here
import btoa from 'btoa-lite';
import Uri from 'jsuri';

export enum SsoEnv {
  PROD = 'PROD',
  STAGE = 'STAGE',
  QA = 'QA',
  DEV = 'DEV',
  DEV1 = 'DEV1',
  DEV2 = 'DEV2'
}

export const createBasicAuth = (user: string, pass: string): string => {
  return `Basic ${btoa(user + ':' + pass)}`;
};

// default to Stage
let apiHostName = new Uri('/');
const pathPrefix = '/hydra/cwe/rest';

// Add any new services consuming hydrajs to below arrays
const fteHostNames = ['fte.foo.redhat.com'];
const prodHostNames = [
  'connect.redhat.com',
  'prod.foo.redhat.com',
  'rhcert-spa-certpx-prod.ext.us-west.aws.prod.paas.redhat.com',
  'rhcert-spa-certpx-prod.ext-waf.us-west.aws.prod.paas.redhat.com',
  'rhcert.connect.redhat.com'
];
const qaHostNames = [
  'connect.qa.redhat.com',
  'qa.foo.redhat.com',
  'rhcert-spa-certpx-qa.ext.us-east.aws.preprod.paas.redhat.com',
  'localhost',
  'rhcert-spa-certpx-qa.ext-waf.us-east.aws.preprod.paas.redhat.com',
  'rhcert.connect.dev.redhat.com'
];
const stageHostNames = [
  'stage-connect.redhat.com',
  'stage.foo.redhat.com',
  'rhcert-spa-certpx-stage.ext.us-east.aws.preprod.paas.redhat.com',
  'rhcert-spa-certpx-stage.ext-waf.us-east.aws.preprod.paas.redhat.com',
  'rhcert.connect.preprod.redhat.com'
];

const getEnvName = (): SsoEnv => {
  // Returns PROD | QA | FTE | CI | STAGE
  if (typeof window !== 'undefined' && window) {
    if (prodHostNames.indexOf(window.location.hostname) !== -1) {
      return SsoEnv.PROD;
    } else if (stageHostNames.indexOf(window.location.hostname) !== -1) {
      return SsoEnv.STAGE;
    } else if (
      qaHostNames.indexOf(window.location.hostname) !== -1 ||
      fteHostNames.indexOf(window.location.hostname) !== -1
    ) {
      return SsoEnv.QA;
    }
  }
  return SsoEnv.QA;
};

export const isProduction = (): boolean => {
  return getEnvName() === SsoEnv.PROD;
};

const getSsoAuthUrl = (): string => {
  const env = getEnvName().toLowerCase();
  return `https://sso.${!isProduction() ? env + '.' : ''}redhat.com/auth/`;
};

export const getpyxisIdURL = (pyxisId: string): URL => {
  const env = getEnvName().toLowerCase();
  return new URL(
    `https://connect.${
      !isProduction() ? env + '.' : ''
    }redhat.com/manage/projects/${pyxisId}/overview`
  );
};

// for custom api hostname
// if (process && process.env && process.env.API_HOSTNAME) {
//   if (process.env.API_HOSTNAME) {
//     apiHostName = new Uri(process.env.API_HOSTNAME);
//   }
// }
if (typeof window !== 'undefined' && window) {
  const env = getEnvName();
  switch (env) {
    case SsoEnv.STAGE:
      apiHostName = new Uri('https://access.stage.redhat.com');
      break;
    case SsoEnv.QA:
      // using fte.foo.redhat.com will connect you to local qa environment
      const url =
        fteHostNames.indexOf(window.location.hostname) !== -1
          ? 'https://access.stage.redhat.com'
          : 'https://access.qa.redhat.com';
      // "https://hydra-cwe-cwe-qa.ext.us-east.aws.preprod.paas.redhat.com"
      apiHostName = new Uri(url);
      break;
    case SsoEnv.PROD:
      apiHostName = new Uri('https://access.redhat.com');
      break;
    default:
      apiHostName = new Uri('https://access.dev.redhat.com');
      break;
  }
}

const getCmsUrl = (): string => {
  const env = getEnvName().toLowerCase();
  const isProd = isProduction();
  return `https://rhcert-cms${isProd ? '' : `-certpx-${env}`}.apps.ext.spoke.${
    isProd ? '' : 'pre'
  }prod.${isProd ? 'us-west-2' : 'us-east-1'}.aws.paas.redhat.com`;
};

export default class Env {
  static apiHostName = apiHostName;
  // Version name: Keep this '' if there's no version.
  // Prefix with / if there is a version prefix eg: '/v1' if url is www.example.com/v1/ping
  static apiVersion = '/v1.0';
  static getEnvName = getEnvName;
  static getSsoAuthUrl = getSsoAuthUrl;
  static getCmsUrl = getCmsUrl;
  static pathPrefix = pathPrefix;
  static EnvNames = SsoEnv;
}

export class rhProductEnv {
  static apiHostName = apiHostName;
  static apiVersion = '/v4';
  static getEnvName = getEnvName;
  static pathPrefix = '/hydra/rest/cwe/rhproducts';
  static EnvNames = SsoEnv;
}
