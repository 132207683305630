import { useAlertContext } from 'context/alert/AlertContext';
import { useReducer, useState } from 'react';

import { useIsMounted } from './useIsMounted';
import { usePrevious } from './usePrevious';

interface IFetchState<T> {
  data?: null | T;
  isFetching?: boolean;
  error?: null | unknown | Error;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IUseFetch<T, A extends any[]> extends IFetchState<T> {
  request: (...args: A) => Promise<T>;
  clearResponse?: () => void;
  abortSignal: AbortController['abort'];
}

export interface IUseFetchOptions<T> {
  initialData?: T;
  resolve?: boolean;
  isAbortive?: boolean;
  propagateErrors?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetch = <T, A extends any[]>(
  fn: (...args: A) => Promise<T>,
  {
    initialData = null as T,
    resolve = false,
    isAbortive = false,
    propagateErrors = false
  }: IUseFetchOptions<T> = {},
  mockData?: T
): IUseFetch<T, A> => {
  const { addFetchErrorMessage } = useAlertContext();

  const isMounted = useIsMounted();
  const [{ data, isFetching, error }, setState] = useReducer(
    (s: IFetchState<T>, a: IFetchState<T>) => ({ ...s, ...a }),
    { data: initialData, isFetching: resolve, error: undefined }
  );
  const controller = new AbortController();
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const prevAbortController = usePrevious(abortController);
  const clearResponse = () => setState({ data: initialData });
  if (isAbortive && 'AbortController' in window && !abortController) {
    setAbortController(controller);
  }

  const request = async (...args: A): Promise<T> => {
    try {
      if (isAbortive && 'AbortController' in window) {
        if (prevAbortController) {
          prevAbortController.abort();
        }
        args.push(controller.signal);
      }
      // check if component is mounted, only then update state
      isMounted && setState({ isFetching: true, error: null });
      let response = null;
      // if mock data is present then mock an api return that
      if (mockData) {
        response = await new Promise((resolve, _reject) => {
          setTimeout(() => {
            resolve(mockData);
          }, 1000);
        });
      } else {
        response = await fn(...args);
      }
      isMounted &&
        setState({ data: response as T, isFetching: false, error: null });
      return response as T;
    } catch (err) {
      const error: unknown | Error = err;
      if (
        error &&
        'name' in (error as Error) &&
        (error as Error).name === 'AbortError'
      ) {
      } else {
        if (!propagateErrors) {
          addFetchErrorMessage(error);
        }
      }
      isMounted && setState({ data: null as T, isFetching: false, error });
      if (propagateErrors) {
        throw error;
      }
      return null as T;
    } finally {
      isMounted && setState({ isFetching: false });
    }
  };

  // useEffect(() => {
  // if (resolve) {
  //     request();
  // }
  // }, resolveCondition);

  return {
    clearResponse,
    data,
    isFetching,
    request,
    error,
    abortSignal: controller?.abort.bind(controller)
  };
};
