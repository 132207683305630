import {
  ENV as SsoEnv,
  HOSTNAMES,
  HYDRA_HOSTNAMES,
  SSO_HOSTNAMES
} from '../constants/env-constant';

const getEnvName = () => {
  if (typeof window !== 'undefined' && window) {
    const hostname = window.location.hostname;
    type HOSTNAME_ENV = keyof typeof HOSTNAMES;
    const HOSTNAME_KEYS = Object.keys(HOSTNAMES) as HOSTNAME_ENV[];

    const index = HOSTNAME_KEYS.findIndex((env) =>
      HOSTNAMES[env].includes(hostname)
    );

    return HOSTNAME_KEYS[index] || SsoEnv.QA;
  }
  return SsoEnv.QA;
};

const getApiUrl = () => {
  const env = getEnvName();
  let url = HYDRA_HOSTNAMES[SsoEnv.QA];
  if (HYDRA_HOSTNAMES[env]) {
    url = HYDRA_HOSTNAMES[env];
  }
  return url;
};

const getAuthUrl = () => {
  const env = getEnvName();
  let url = SSO_HOSTNAMES[SsoEnv.QA];
  if (SSO_HOSTNAMES[env]) {
    url = SSO_HOSTNAMES[env];
  }
  return url;
};

class Env {
  static isProd = () => getEnvName() === SsoEnv.PROD;
  static getEnvName = getEnvName;
}
export { getApiUrl, getAuthUrl };
export default Env;
