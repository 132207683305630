import type { IProductCategory } from 'models/products';
import type {
  ISoftwareSubCategory,
  ITaxonomyVocabularies
} from 'models/artifacts';
import type { ICertificationType, IEcosystem } from 'models/certifications';
import type { IActionType } from 'models/common';
import type { IRHProductPlatforms, IProductVersion } from 'models/products';

export enum ArtifactMetaDataReducerConstants {
  getArtifactMetaData = 'getArtifactMetaData',
  getRedHatProductVersions = 'getRedHatProductVersions',
  getSoftwareSubCategory = 'getSoftwareSubCategory'
}

export interface IArtifactMetaDataState {
  ecosystems: IEcosystem[];
  productCategories: IProductCategory[];
  certificationTypes: ICertificationType[];
  redHatProductVersions: IProductVersion[];
  platforms: IRHProductPlatforms[];
  taxonomyVocabularies: ITaxonomyVocabularies[];
  softwareSubCategory?: ISoftwareSubCategory[];
}

export const initialArtifactMetaDataState: IArtifactMetaDataState = {
  ecosystems: [],
  productCategories: [],
  certificationTypes: [],
  redHatProductVersions: [],
  platforms: [],
  taxonomyVocabularies: []
};

export default (
  state: IArtifactMetaDataState,
  action: IActionType<IArtifactMetaDataState>
): IArtifactMetaDataState => {
  switch (action.type) {
    case ArtifactMetaDataReducerConstants.getArtifactMetaData:
      return {
        ...state,
        ...action.payload
      };
    case ArtifactMetaDataReducerConstants.getRedHatProductVersions:
      return {
        ...state,
        redHatProductVersions: action.payload.redHatProductVersions
      };
    case ArtifactMetaDataReducerConstants.getSoftwareSubCategory:
      return {
        ...state,
        softwareSubCategory: action.payload.softwareSubCategory
      };
    default:
      return state;
  }
};
